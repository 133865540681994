const React = require('react')
const PropTypes = require('prop-types')
const {utils: {createReactElement, createSantaTypesDefinitions}, santaTypesDefinitions} = require('santa-components')

const santaTypes = createSantaTypesDefinitions({
    navigateTo: PropTypes.func,
    isExternalUrl: PropTypes.func,
    shouldShowBoltSiteContainerChildren: PropTypes.bool,
    isWixSite: PropTypes.bool
}, 'BoltSite')

const getClosestAncestorByTagName = (element, parentTagName) => {
    if (!element || !element.tagName) {
        return null
    }
    if (element.tagName.toLowerCase() === parentTagName) {
        return element
    }
    return getClosestAncestorByTagName(element.parentNode, parentTagName)
}

const getAnchorNode = event => getClosestAncestorByTagName(event.target, 'a')

const cancelEvent = event => {
    event.stopPropagation()
    event.preventDefault()
    return false
}

const getNavInfo = linkNode => ({
    href: linkNode.getAttribute('href'),
    target: linkNode.getAttribute('target'),
    pageItemAdditionalData: linkNode.getAttribute('data-page-item-context'),
    anchorData: linkNode.getAttribute('data-anchor'),
    noUrlChangeAttr: linkNode.getAttribute('data-no-physical-url'),
    isChangingUrl: !linkNode.getAttribute('data-no-physical-url'), //maybe skip this
    isKeepingRoots: !!linkNode.getAttribute('data-keep-roots')
})

const siteClickHandler = (navigateMethod, isExternalUrl) => e => {
    const linkNode = getAnchorNode(e)
    let navInfo
    let isExternalLink = false
    if (linkNode) {
        navInfo = getNavInfo(linkNode)
        const url = navInfo.noUrlChangeAttr || navInfo.href
        isExternalLink = navInfo.target === '_blank' || isExternalUrl(url)
    }
    if (!linkNode || isExternalLink) {
        return true //let the browser do it's thing
    }

    navigateMethod(navInfo)
    return cancelEvent(e) //we got this
}

const getClassList = props => {
    const res = []
    const {isWixSite, isVisualFocusEnabled} = props
    if (isWixSite) {
        res.push('wixSiteProperties')
    } else {
        res.push('noop')
    }

    if (isVisualFocusEnabled) {
        res.push('visual-focus-on')
    }

    return res.join(' ')
}

/**
 * @class components.BoltSite
 */
class BoltSite extends React.Component {
    render() {
        const {navigateTo, isExternalUrl} = this.props
        return createReactElement('div', {
            key: 'BoltSite',
            className: getClassList(this.props),
            style: {
                position: 'relative'
            },
            onClick: siteClickHandler(navigateTo, isExternalUrl)
        }, this.props.shouldShowBoltSiteContainerChildren ? this.props.children : [])
    }
}

BoltSite.displayName = 'BoltSite'
BoltSite.santaTypeDefinitions = santaTypes
BoltSite.compType = 'BOLT_SITE'
BoltSite.propTypes = {
    children: PropTypes.node,
    navigateTo: santaTypes.navigateTo.isRequired,
    isExternalUrl: santaTypes.isExternalUrl.isRequired,
    isVisualFocusEnabled: santaTypesDefinitions.isVisualFocusEnabled.isRequired,
    isWixSite: santaTypes.isWixSite.isRequired,
    shouldShowBoltSiteContainerChildren: santaTypes.shouldShowBoltSiteContainerChildren
}

BoltSite.defaultProps = {
    shouldShowBoltSiteContainerChildren: true
}

module.exports = BoltSite
