const React = require('react')
const PropTypes = require('prop-types')

/**
 * @class components.CssStyleRenderer
 */
class CssStyleRenderer extends React.Component {
    static displayName = 'CssStyleRenderer'
    static compType = 'CssStyleRenderer'
    static propTypes = {
        cssString: PropTypes.string.isRequired,
        cssName: PropTypes.string.isRequired
    }

    render() {
        const {cssString, cssName} = this.props
        return (
            <style type='text/css' id={cssName}
                dangerouslySetInnerHTML={{__html: cssString}}/>
        )
    }
}

/**
 * @class components.FontsContainer
 */
class FontsContainer extends React.Component {
    static displayName = 'FontsContainer'
    static compType = 'FONTS_CONTAINER'
    static propTypes = {
        fontsCssArr: PropTypes.array
    }
    render() {
        const {fontsCssArr} = this.props
        const id = 'FONTS_CONTAINER'
        return <div id={id} key={id}>
            {fontsCssArr.map(fontCssObj => <CssStyleRenderer key={fontCssObj.name} cssName={fontCssObj.name} cssString={fontCssObj.css}/>)}
        </div>
    }
}

module.exports = FontsContainer
